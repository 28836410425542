<template>
    <div class="home-service-form">
        <div class="form-container">
            <div class="form-body">

                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>标题</span>
                            <em>*</em>
                        </div>
                    </div>
                    <div class="item">
                        <n-input placeholder="请输入标题" v-model:value="formData.title" />
                    </div>
                </div>

                <div class="form-item">
                    <div class="label">
                        <div class="label-container">
                            <span>内容</span>
                            <em>*</em>
                        </div>
                    </div>
                    <div class="item">
                        <n-textarea 
                            v-model:value="formData.content"
                            :maxlength="240"
                        ></n-textarea>
                    </div>
                </div>

                <div class="form-btns">
                    <n-button class="btn" size="large">重置</n-button>
                    <n-button class="btn" type="primary" size="large"
                        :disabled="formData.title === '' || formData.content === ''"
                        @click="$emit('submit', formData)"
                    >确定</n-button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeServiceForm',
    props: {
        data: {
            type: Object,
            default: {}
        },
        btnLoading: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            formData: {
                title: '',
                content: '',
                order: 1,
            }
        }
    },
    watch:{
        'data':{
            handler(){
                this.formData = this.data;
            },
            deep: true
        }
    },
    emits: ['submit'],
    mounted(){
        this.formData = this.data
    }
}
</script>

<style lang="scss" scoped>
@import 'form-style';
.home-service-form{
    margin-bottom: 15px;
}
.form-btns{
    @extend .flex-row-center;
    margin-top: 10px;
    .btn{
        width: 20%;
        margin: 0 10px;
    }
}
</style>