<template>
    <breadcrumb />
    <div class="recommend-service">
        <template v-for="(item, index) in service" :key="index">
            <home-service-form :data="item" :btnLoading="loading" @submit="submit" />
        </template>
    </div>
    <n-notification 
        v-model:show="notification.show"
        :content="notification.content"
    >
        <template v-slot:icon>
            <attention v-if="notification.state === 'error'" theme="outline" size="24" fill="#ff2121"/>
            <check-one v-else theme="outline" size="24" fill="#00c12b"/>
        </template>
    </n-notification>
</template>

<script>
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import HomeServiceForm from '@/components/forms/ServiceForm.vue';
import { Attention, CheckOne } from '@icon-park/vue-next';

export default {
    name: 'RecommendService',
    components: {
        Breadcrumb, HomeServiceForm,
        Attention, CheckOne 
    },
    data(){
        return {
            service:[
                {
                    title: 'Pre-sale service',
                    content: '',
                    order: 1,
                    id: 1
                },{
                    title: 'Production monitoring',
                    content: '',
                    order: 2,
                    id: 2
                },{
                    title: 'Production monitoring',
                    content: '',
                    order: 1,
                    id: 3
                }
            ],
            loading: false,
            notification: {
                show: false,
                content: '',
                state: 'error'
            }
        }
    },
    mounted(){
        this.getService();
    },
    methods:{
        getService(){
            let that = this;
            this.$api.xrHomeService().then(res => {
                if(res.data.length > 0) {
                    res.data.forEach((item, index) => {
                        that.service[index] = item
                    })
                }
            })
        },
        submit(e){
            this.$api.xrHomeServiceUpdate(e.id, e).then(res => {
                console.log(res)
                this.loading = false;
                if(res.msg === 'success'){
                    this.notification = {
                        show: true,
                        content: '成功',
                        state: 'success'
                    }
                } else {
                    this.notification = {
                        show: true,
                        content: res.msg,
                        state: 'error'
                    }
                }
            })
        }
    }
}
</script>